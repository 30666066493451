














import { Component, Vue, Prop } from 'vue-property-decorator';
import { CourseCustomLabel } from '../../../../types/typescript-axios/api';
@Component({})
export default class LabelCustomLabels extends Vue {
  @Prop({ default: () => null })
  private label!: CourseCustomLabel;

}
