













































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  courseTimeStatus,
  mansekiStatus,
  isReservedStatus,
  isReservedStatus2,
  isWaitingList,
  isCourseApplicationMethod,
} from '../../../plugins/helper';
import { CourseResponseCourses } from '../../../../types/typescript-axios/api';
@Component({
})
export default class LabelStatus extends Vue {
  @Prop({default: {}})
  private course!: CourseResponseCourses;

  private get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }

  private get isReserveStatus(): number {
    const nowDate: Date = new Date();
    const courseStatus: number = courseTimeStatus(this.course);
    const isStatus1: number = isReservedStatus(this.course);
    const isStatus2: number = isReservedStatus2(this.course);
    const isManseki: boolean = mansekiStatus(this.course);
    const isCourseMethod: number = isCourseApplicationMethod(this.course);
    const isWaiting: boolean = isWaitingList(this.course);

    // 開催終了 終了
    if (courseStatus === 3) {
      return 1;
    }

    // 申込み中
    if (
      courseStatus < 3 &&
      (
        (isStatus1 === 0 && isStatus2 === 7) ||
        (isStatus1 === 0 && isStatus2 === 8)
      )
    ) {
      return 2; // 申込み中
    }

    // 抽選結果待ち
    if (
      courseStatus < 3 &&
      (isStatus1 === 0 && isStatus2 === 6)
    ) {
      return 7; // 抽選結果待ち
    }

    // 受講確定
    if (
      courseStatus < 3 &&
      (
        (isStatus1 === 0 && isStatus2 === 9) ||
        (isStatus1 === 0 && isStatus2 === 1)
      )
    ) {
      return 8; // 受講確定
    }

    // キャンセル待ち申込み中
    if (
      courseStatus < 3 &&
      (isStatus1 === 2)
    ) {
      return 6; // キャンセル待ち申込み中
    }

    if (courseStatus === 0) { // 受付前 予告
      return 0;
    }

    if (courseStatus === 2) { // 受付期間終了 受付終了
      return 3;
    }

    if (
      isManseki && // 満席
      courseStatus === 1 // 申込み受付期間中
    ) {
      if (
        isCourseMethod === 0 && // 先着順
        !isWaiting && // キャンセル待ち受付不可講座
        (isStatus1 === -1 || isStatus1 === 1) // 未申込み || キャンセル
      ) {
        return 4; // 満席
      } else if (
        isCourseMethod === 0 && // 先着順
        isWaiting && // キャンセル待ち受付不可講座
        (isStatus1 === -1 || isStatus1 === 1) // 未申込み || キャンセル
      ) {
        return 9; // キャンセル待ち受付中
      }

    }


    if (courseStatus < 2) { // 受付前 予告
      return 5; // 受付中
    }

    return -1;
  }
}
